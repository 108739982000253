import { IAgentPartner } from '@/models/page/logon/ILogon';
import getAppStorageUtil, { STORAGE_KEYS } from '@/service/appStorageUtil';

export enum PARTNER_ENUM {
    M6 = 1,
    BB = 2,
    N8 = 3,

    ALL = 0,
    None = -1,
}

export const PARTNERS_CONFIGS: IPartnerConfig[] = [
    {
        id: PARTNER_ENUM.M6,
        text: 'M6',
    },
    {
        id: PARTNER_ENUM.BB,
        text: 'BB',
    },
    {
        id: PARTNER_ENUM.N8,
        text: 'N8',
    },
];

export const PARTNERS_CONFIGS_MAP: { [id: number]: IPartnerConfig } = PARTNERS_CONFIGS.reduce((mapping, config) => {
    mapping[config.id] = config;
    return mapping;
}, {} as { [id: number]: IPartnerConfig });

export type IPartnerConfig = {
    id: number;
    text: string;
};

const [, getItem] = getAppStorageUtil();
export function getEnableConfig(onlyEnabled = false): IPartnerConfig[] {
    const partners = getItem<IAgentPartner[]>(STORAGE_KEYS.AGENTPARTNER_KEY);

    if (!partners) return [];

    return PARTNERS_CONFIGS
        .filter((config) =>
            partners
                .some(partner =>
                    partner.PartnerId === config.id &&
                    (onlyEnabled ? partner.IsEnabled : true)
                )
        )
        .sort((a, b) => a.id - b.id);
}
