import { APIModel } from '@/models/apiRequest';

export const api: { [key: string]: APIModel } = {
    gameReport: {
        path: '/report/game',
        method: 'POST',
    },
    gameDetails: {
        path: '/report/game-details',
        method: 'POST',
    },
    commissionReport: {
        path: '/report/commission',
        method: 'POST',
    },
    firstRechargeReport: {
        path: '/report/first-recharge',
        method: 'POST',
    },
    teamReport: {
        path: '/report/team',
        method: 'POST',
    },
    agentRechargeReport: {
        path: '/report/agent-recharge',
        method: 'POST',
    },
    fundReport: {
        path: '/report/fund',
        method: 'POST',
    },
    rebateReport: {
        path: '/report/rebate',
        method: 'POST',
    },
    memberReport: {
        path: '/report/member',
        method: 'POST',
    },
    chargeWithdrawalReport: {
        path: '/report/charge-and-withdrawal',
        method: 'POST',
    },
    rebateDetails: {
        path: '/report/rebate-details',
        method: 'POST',
    },
    commissionMember: {
        path: '/report/commission-member',
        method: 'POST',
    },
    dashboard: {
        path: '/dashboard',
        method: 'POST',
    },
    hostConfig: {
        path: '/config/api-host-config.json',
        method: 'GET',
    },
    logon: {
        path: '/logon',
        method: 'POST',
    },
    depositDetails: {
        path: '/report/depositDetails',
        method: 'POST',
    },
    registerMembers: {
        path: '/report/register-members',
        method: 'POST',
    },
    proxyCommission: {
        path: '/report/proxy-commission',
        method: 'POST',
    },
    memberDetails: {
        path: '/report/member-details',
        method: 'POST',
    },
    updateSetting: {
        path: '/profile/update-setting',
        method: 'POST',
    },
    resetPassword: {
        path: '/profile/reset-password',
        method: 'POST',
    },
    getPromotionLink: {
        path: '/report/get-promotion-link',
        method: 'POST',
    },
    createRechargeRecord: {
        path: '/profile/create-recharge-record',
        method: 'POST',
    },
    getRechargeOrders: {
        path: '/profile/get-recharge-orders',
        method: 'POST',
    },
    updateRechargeOrders: {
        path: '/profile/update-recharge-order',
        method: 'POST',
    },
    memberCommissionHistory: {
        path: '/report/member-commission-history',
        method: 'POST',
    },
    commissionHistory: {
        path: '/report/commission-history',
        method: 'POST',
    },
    relationMemberReport: {
        path: '/report/member-group',
        method: 'POST',
    },
    relationMemberReportDetail: {
        path: '/report/member-group-detail',
        method: 'POST',
    },
    memberMonthlyReport: {
        path: '/report/member-monthly-report',
        method: 'POST',
    },
    relationMemberGroupDetail: {
        path: '/report/get-member-group-detail',
        method: 'POST',
    },
    relationUpdateMemberGroup: {
        path: '/report/update-member-group',
        method: 'POST',
    },
    relationCheckRelatedMember: {
        path: '/report/check-related-member',
        method: 'POST',
    },
    updateMemberContact: {
        path: '/member-admin/update-member-contact',
        method: 'POST',
    },
    memberStatistics: {
        path: '/member-admin/member-statistic',
        method: 'POST',
    },
    memberBaseInfo: {
        path: '/member-admin/member-base-info',
        method: 'POST',
    },
    agentDetail: {
        path: '/profile/agent-detail',
        method: 'POST',
    },
    updatePartnerPassword: {
        path: '/profile/update-partner-password',
        method: 'POST',
    },
    searchCode: {
        path: '/search-code',
        method: 'POST',
    },
    saveAndUpdateSubAccount: {
        path: '/system/save-sub-account',
        method: 'POST',
    },
    subAccountList: {
        path: '/system/sub-account-list',
        method: 'POST',
    },
    getAuditLogList: {
        path: '/system/get-audit-log-list',
        method: 'POST',
    },
    getUserPartnerAgentList: {
        path: '/system/user-partner-agent-list',
        method: 'POST',
    },
    subAccountDetails: {
        path: '/system/sub-account-detail',
        method: 'POST',
    },
    kickOut: {
        path: '/profile/kick-out',
        method: 'POST',
    },
    crossSystemMember: {
        path: '/member-admin/cross-system-query',
        method: 'POST',
    },
    getVChatUser: {
        path: '/vchat/get-user',
        method: 'POST',
    },
    addVChatUserFriend: {
        path: '/vchat/apply-friend',
        method: 'POST',
    },
    getVChatRegisterCode: {
        path: '/vchat/registercode-map ',
        method: 'POST',
    },
    getVChatFriendList: {
        path: '/vchat/friend-list',
        method: 'POST',
    },
    memberChatHistory: {
        path: '/vchat/chat-history',
        method: 'POST',
    },
    getVChatToken: {
        path: '/vchat/get-auth-token',
        method: 'POST',
    },
    getUserAccountsVchatInfo: {
        path: '/vchat/get-user-accounts-vchatInfo',
        method: 'POST',
    },
    saveORUpdateRegisterCode: {
        path: '/vchat/save-registercode',
        method: 'POST',
    },
    getChatGroup: {
        path: '/vchat/get-group',
        method: 'POST',
    },
    getRegisterCodeGroupConfig: {
        path: '/vchat/get-registercode-group-config',
        method: 'POST',
    },
    saveRegisterCode: {
        path: '/vchat/save-registercode',
        method: 'POST',
    },
    getRegisterCodeDetail: {
        path: '/vchat/get-registercode-detail',
        method: 'POST',
    },
    saveVChatUserBinding: {
        path: '/profile/save-user-binding',
        method: 'POST',
    },
    getTransactionType: {
        path: '/report/get-transaction-type',
        method: 'POST',
    },
    fundsDetail: {
        path: '/report/funds-detail',
        method: 'POST',
    },
    exportFundReport: {
        path: '/report/fund/export',
        method: 'POST',
    },
    exportCommissionReport: {
        path: '/report/commission/export',
        method: 'POST',
    },
    exportTeamReport: {
        path: '/report/team/export',
        method: 'POST',
    },
    exportMemberReport: {
        path: '/report/member/export',
        method: 'POST',
    },
    exportGameReport: {
        path: '/report/game/export',
        method: 'POST',
    },
    exportFirstRechargeReport: {
        path: '/report/first-recharge/export',
        method: 'POST',
    },
    exportRebateReport: {
        path: '/report/rebate/export',
        method: 'POST',
    },
    exportChargeAndWithdrawalReport: {
        path: '/report/charge-and-withdrawal/export',
        method: 'POST',
    },
    exportAgentRechargeReport: {
        path: '/report/agent-recharge/export',
        method: 'POST',
    },
    exportMemberGroupReport: {
        path: '/report/member-group/export',
        method: 'POST',
    },
    exportRegisterMembersReport: {
        path: '/report/register-members/export',
        method: 'POST',
    },
    exportProxyCommissionReport: {
        path: '/report/proxy-commission/export',
        method: 'POST',
    },
    exportCommissionMemberReport: {
        path: '/report/commission-member/export',
        method: 'POST',
    },
    exportDepositDetailsReport: {
        path: '/report/depositDetails/export',
        method: 'POST',
    },
    exportGameDetailsReport: {
        path: '/report/game-details/export',
        method: 'POST',
    },
    exportRebateDetailsReport: {
        path: '/report/rebate-details/export',
        method: 'POST',
    },
    exportMemberGroupDetailReport: {
        path: '/report/member-group-detail/export',
        method: 'POST',
    },
    getTransferStatus: {
        path: '/profile/get-transfer-status',
        method: 'POST',
    },
} as const;
